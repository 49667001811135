import 'whatwg-fetch'

import * as React from 'react'
import * as ReactDOM from 'react-dom'

import App from './App'

export const renderApp = () => {
  ReactDOM.render(<App />, document.getElementById('app'))
}
renderApp()
