import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router'
import { useLocation, useRouteMatch } from 'react-router-dom'

import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  Content,
  TopNav,
  SubMenu,
  ApiInsights,
} from '@debbie/iris/dist/components'

import DefaultReporter from './DefaultReporter'

const Reports = () => {
  const lang = useSelector((s: RootState) => s.lang)
  const match = useRouteMatch()
  const location = useLocation()
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  const items = [
    {
      text: lang.navigation.reportsExorts,
      link: `${match.url}`,
    },
    {
      text: 'Power BI',
      link: `${match.url}/powerbi`,
    },
  ]

  return (
    <div>
      <TopNav />
      <Content>
        <PageBase>
          <SubMenu items={items} />
          <Switch location={location}>
            <div>
              <Route exact path="/reports">
                <DefaultReporter />
              </Route>
              <Route exact path="/reports/powerbi">
                <ApiInsights isClient={true} creditorId={selectedCreditor} />
              </Route>
            </div>
          </Switch>
        </PageBase>
      </Content>
    </div>
  )
}

export default Reports
