import { CaseStatus } from '@debbie/common/dist/cases'

export const NAV_LEFT_WIDTH = 250
export const NAV_TOP_HEIGHT = 60
export const SUB_MENU_HEIGHT = 60

export const COLOR_PRIMARY = '#200D54'
export const COLOR_SECONDARY = '#f4f4f4'
export const DANGEROUS = '#dc3545'
export const WARNING = '#ffc107'
export const SUCCESS = '#28a745'
export const DARK_COLOR_SECONDARY = '#A2A2A2'
export const SELECTED_BLUE = '#08C'

export const STATUS_COLORS = {
  [CaseStatus.VERIFIED]: '#98D679',
  [CaseStatus.PENDING]: '#E5E56E',
  [CaseStatus.ENDED]: '#515D76',
}

export const STATUS_CHART_COLORS = {
  ...STATUS_COLORS,
  END_REASON: '#ffc658',
}

export const STATUS_CHART_TRANSLATIONS = {
  [STATUS_CHART_COLORS[CaseStatus.VERIFIED]]: 'Godkendt',
  [STATUS_CHART_COLORS[CaseStatus.PENDING]]: 'Afventer Godkendelse',
  [STATUS_CHART_COLORS[CaseStatus.ENDED]]: 'Afsluttet',
  [STATUS_CHART_COLORS.END_REASON]: 'Afslutningsårsag',
}

export const RESOLVE_DURATION_CHART_COLORS = {
  zero_to_three: '#0088FE',
  three_to_six: '#00C49F',
  six_to_nine: '#FFBB28',
  nine_to_twelve: '#FF8042',
  twelve_plus: '#515D76',
}

export const RESOLVE_DURATION_CHART_TRANSLATIONS = {
  [RESOLVE_DURATION_CHART_COLORS.zero_to_three]: '0-3',
  [RESOLVE_DURATION_CHART_COLORS.three_to_six]: '3-6',
  [RESOLVE_DURATION_CHART_COLORS.six_to_nine]: '6-9',
  [RESOLVE_DURATION_CHART_COLORS.nine_to_twelve]: '9-12',
  [RESOLVE_DURATION_CHART_COLORS.twelve_plus]: '12+',
}

export const PAYMENT_PLANS_CHART_COLORS = {
  done: '#98D679',
  running: '#E5E56E',
  not_started: '#515D76',
}

export const PAYMENT_PLANS_CHART_TRANSLATIONS = {
  [PAYMENT_PLANS_CHART_COLORS.done]: 'Fuldt Betalt',
  [PAYMENT_PLANS_CHART_COLORS.running]: 'Delvist Betalt',
  [PAYMENT_PLANS_CHART_COLORS.not_started]: 'Ikke Begyndt',
}

export const MEDIA_QUERY_MOBILE = 'screen and (max-width: 770px)'

export const GRID_SPACING = 20

export const DATE_FORMAT = 'Do MMMM YYYY'
export const DATE_FORMAT_LONG = 'Do MMMM YYYY, H:mm:ss'
export const DATE_FORMAT_SHORT = 'DD.MM.YYYY'
