import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router'

import { RootState } from '@debbie/cortex/dist/reducer'
import { SubTabs } from '@debbie/iris/dist/components'

import API from './API'

const Developers = (props: {}) => {
  const lang = useSelector((s: RootState) => s.lang)
  const location = useLocation()

  return (
    <div>
      <SubTabs
        items={[
          {
            label: lang.navigation.settingsAPI,
            link: '/settings/developers',
          },
        ]}
      />
      <Switch location={location}>
        <Route exact path="/settings/developers">
          <API />
        </Route>
      </Switch>
    </div>
  )
}

export default Developers
