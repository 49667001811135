import React from 'react'
import { useSelector } from 'react-redux'

import { PageBase, Panel, TopNav } from '@debbie/iris/dist/components'
import Integration from '@debbie/iris/dist/components/integrations/Integration'

type Props = {
  id: string
  creditorId: string
  token?: string
  options?: string
}

const CreditorIntegration = (props: Props) => {
  return (
    <>
      <TopNav />
      <PageBase>
        <Panel
          style={{
            maxWidth: '1400px',
            margin: '0px auto',
          }}
        >
          <Integration
            creditorId={props.creditorId}
            id={props.id}
            redirectUrl={`${window.location.origin}/${props.creditorId}/integrations/${props.id}`}
            token={props.token}
            options={props.options}
          ></Integration>
        </Panel>
      </PageBase>
    </>
  )
}

export default CreditorIntegration
