export const APIBASE = `${process.env.APIBASE}/collection-engine`

export const FILEBASE = `${APIBASE}/files`

export const GOOGLE_MAPS_API_KEY = process.env.GOOGLE_MAPS_API_KEY

export const SENTRY_DSN = process.env.SENTRY_DSN
export const ENABLE_SENTRY = process.env.ENABLE_SENTRY === 'true'

export const ENV = process.env.ENV

export const IS_PROD = ENV === 'production'
