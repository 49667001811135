import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { h } from '@debbie/cortex/dist'
import { RootState } from '@debbie/cortex/dist/reducer'
import { PageBase, Panel, TopNav } from '@debbie/iris/dist/components'
import CreditorSelector from '@debbie/iris/dist/components/integrations/CreditorSelector'

type Props = {}

const IntegrationsPage = (props: Props) => {
  const history = useHistory()
  const lang = useSelector((state: RootState) => state.lang)
  const allCreditors = useSelector((state: RootState) => state.allCreditors)

  useEffect(() => {
    if (h.isLoaded(allCreditors) && allCreditors.data.length === 1) {
      history.push(`/${allCreditors.data[0].creditorId}/integrations`)
    }
  }, [allCreditors])

  if (!h.hasData(allCreditors)) {
    return null
  }

  return (
    <>
      <TopNav />
      <PageBase>
        <Panel
          style={{
            maxWidth: '1400px',
            margin: '0px auto',
          }}
          header={lang.integrations.chooseCreditor}
        >
          <CreditorSelector
            onSelect={(creditor) => {
              history.push(`/${creditor.creditorId}/integrations`)
            }}
          ></CreditorSelector>
        </Panel>
      </PageBase>
    </>
  )
}

export default IntegrationsPage
