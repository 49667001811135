export const NOT_ENDED = 'Not ended'
export const DEFAULT_OUTER_RADIUS = 130

export interface InternalPieChartDataEntry {
  categoryName: string
  count: number
}

export interface PieChartPayloadEntry {
  name: string
  label: string
  value: number
}

export const chartDataChanged = (currentData, newData) =>
  currentData.reduce((acc, prevProp) => {
    const newProp = newData.find(
      (p) => p.categoryName === prevProp.categoryName,
    )
    return acc || !newProp || prevProp.count !== newProp.count
  }, false)

export const getLabelWithPercentage = (props) => {
  const percentage = props.percent * 100
  return `${(percentage > 0 && percentage < 1 ? 1 : percentage).toFixed(0)}%`
}
