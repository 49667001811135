import moment from 'moment'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { BlameLogRefType } from '@debbie/common/dist/blamelog'
import { Creditor } from '@debbie/common/dist/creditor'
import { c } from '@debbie/cortex/dist'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  Content,
  CopyToClipboard,
  Map,
  PageBase,
  PageMenu,
  Panel,
  PlaceholderLoader,
  StreetView,
  TopNav,
} from '@debbie/iris/dist/components'
import BlameLog from '@debbie/iris/dist/components/BlameLog/BlameLog'
import CasesOnCustomer from '@debbie/iris/dist/components/Customers/CasesOnCustomer'
import CasesRelatedToCustomer from '@debbie/iris/dist/components/Customers/CasesRelatedToCustomer'
import CustomerPreview from '@debbie/iris/dist/components/Customers/CustomerPreview'
import UserInfo from '@debbie/iris/dist/components/Customers/UserInfo'
import { Col, Row } from '@debbie/iris/dist/components/Grid'
import THEME from '@debbie/iris/dist/theme'

const Customer = (props: { creditor: Creditor }) => {
  const { customerId, userId } = useParams<{
    customerId: string
    creditorId: string
    userId?: string
  }>()

  const customer = useSelector((s: RootState) =>
    selectCustomerById(s, customerId),
  )
  const lang = useSelector((s: RootState) => s.lang)

  useEffect(() => {
    if (!customer) {
      c.customers.getById(customerId)
    }
  }, [customerId])

  if (!customer)
    return (
      <div>
        <PlaceholderLoader />
      </div>
    )

  const selectedUser =
    customer.users.find((u) => u.userId === userId) || customer.users[0]

  return (
    <div>
      <TopNav />
      <Content>
        <PageBase>
          <PageMenu
            breadCrumb={[
              {
                path: `/creditors/${props.creditor.creditorId}`,
                text: props.creditor.name,
              },
              {
                path: `/creditors/${props.creditor.creditorId}/customers`,
                text: lang.navigation.creditorCustomers,
              },
              {
                path: `/creditors/${props.creditor.creditorId}/customers/${customer.customerId}`,
                text: customer.referenceId,
              },
            ].concat(
              selectedUser
                ? {
                    path: `/creditors/${props.creditor.creditorId}/customers/${customer.customerId}/users/${selectedUser.userId}`,
                    text: selectedUser.details.name,
                  }
                : [],
            )}
          />
          <Row>
            <Col width="4">
              <Panel header={lang.customers.customer}>
                <CustomerPreview
                  customerId={customer.customerId}
                  selectedUserId={selectedUser?.userId}
                  onClickOnUser={(user) =>
                    c.redirect.redirect(
                      `/creditors/${props.creditor.creditorId}/customers/${customer.customerId}/users/${user.userId}`,
                    )
                  }
                  noPreview
                />
                <p
                  style={{
                    marginBottom: 0,
                    fontSize: '.8em',
                  }}
                >
                  {lang.customers.createdAt}{' '}
                  {moment(customer.createdAt).format(THEME.DATE_FORMAT_LONG)}
                </p>
              </Panel>
              <Panel header={lang.customers.casesOnCustomer}>
                <p>
                  {lang.customers.casesOnCreditor} <b>{props.creditor.name}</b>{' '}
                  {lang.customers.thatIsRelatedTo} <b>{customer.referenceId}</b>
                  .
                </p>
                <CasesOnCustomer customer={customer} />
              </Panel>
              <Panel header={lang.customers.relatedCases}>
                <p>{lang.customers.casesRelatedToCustomerDescription}</p>
                <CasesRelatedToCustomer customerId={customer.customerId} />
              </Panel>
            </Col>
            <Col width="5">
              {selectedUser && (
                <Panel
                  header={
                    <CopyToClipboard
                      text={selectedUser && selectedUser.userId}
                      hideIcon
                    >
                      <span>{lang.customers.information}</span>
                    </CopyToClipboard>
                  }
                >
                  <UserInfo customer={customer} user={selectedUser} />
                </Panel>
              )}
            </Col>
            <Col width="3">
              {selectedUser && (
                <div
                  style={{
                    marginTop: '48px',
                  }}
                >
                  {selectedUser.details.address && (
                    <div style={{ marginBottom: `${THEME.GRID_SPACING}px` }}>
                      <StreetView
                        address={selectedUser.details.address}
                        width="100%"
                      />
                    </div>
                  )}
                  {selectedUser.details.address && (
                    <div>
                      <Map
                        address={selectedUser.details.address}
                        longitude={
                          selectedUser.details.geocode &&
                          selectedUser.details.geocode.longitude
                        }
                        latitude={
                          selectedUser.details.geocode &&
                          selectedUser.details.geocode.latitude
                        }
                        width="100%"
                        height="450px"
                        key={JSON.stringify(selectedUser.details.address)}
                      />
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </PageBase>
      </Content>
    </div>
  )
}

export default Customer
