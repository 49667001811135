import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@debbie/cortex/dist/reducer'
import APIKeys from '@debbie/iris/dist/components/APIKeys'
import Panel from '@debbie/iris/dist/components/Panel'


const API = (props: {}) => {
  const lang = useSelector((s: RootState) => s.lang)

  return (
    <Panel header={lang.navigation.settingsAPI}>
      <APIKeys />
    </Panel>
  )
}

export default API
