import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { Creditor } from '@debbie/common/dist/creditor'
import { RootState } from '@debbie/cortex/dist/reducer'
import { Content, PageBase, Panel, TopNav } from '@debbie/iris/dist/components'
import CaseVoucherTable from '@debbie/iris/dist/components/CaseVoucherTable'

type Props = {}

const CaseVouchers = (props: Props) => {
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  return (
    <>
      <TopNav />
      <Content withSubMenu>
        <PageBase>
          <Panel>
            <CaseVoucherTable
              pageSize={50}
              filter={{ creditorId: selectedCreditor }}
            ></CaseVoucherTable>
          </Panel>
        </PageBase>
      </Content>
    </>
  )
}

export default CaseVouchers
