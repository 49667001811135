import React from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '@debbie/cortex/dist/reducer'
import { Panel, TopNav } from '@debbie/iris/dist/components'
import Billings from '@debbie/iris/dist/components/economy/Billings'
import { Container } from '@debbie/iris/dist/components/Grid'

const Billing = () => {
  const lang = useSelector((s: RootState) => s.lang)
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  return (
    <div>
      <TopNav />
      <Container>
        <Panel header={lang.billing.bills}>
          <Billings creditorId={selectedCreditor} />
        </Panel>
      </Container>
    </div>
  )
}

export default Billing
