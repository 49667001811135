import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useLocation } from 'react-router'

import { selectMe } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  TopNav,
  PageBase,
  UserSettings,
  Content,
  SubMenu,
} from '@debbie/iris/dist/components'

import Developers from './developers/Developers'

const Settings = () => {
  const location = useLocation()

  const me = useSelector(selectMe)
  const lang = useSelector((s: RootState) => s.lang)

  const settingsItems = [
    {
      text: lang.navigation.settingsGeneral,
      link: '/settings',
    },
    {
      text: lang.navigation.settingsDevelopers,
      link: '/settings/developers',
    },
  ]

  return (
    <div>
      <TopNav />
      <Content withSubMenu={true}>
        <PageBase>
          <SubMenu items={settingsItems} />
          <Switch location={location}>
            <Route path="/settings/developers">
              <Developers />
            </Route>
            <Route path="/settings">
              {me && <UserSettings userId={me.id} />}
            </Route>
          </Switch>
        </PageBase>
      </Content>
    </div>
  )
}

export default Settings
