import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { CreditorState } from '@debbie/common/dist/creditor'
import { selectAllCreditors } from '@debbie/cortex/dist/creditors/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { CaseCreator } from '@debbie/iris/dist/components'
import { CaseCreationStep } from '@debbie/iris/dist/components/CaseCreator/CaseCreator'

type Props = {}

const genLink = (caseId: string | null, step: CaseCreationStep) => {
  return caseId != null ? `/create-case/${caseId}/${step}` : null
}

const CreateCase = (props: Props) => {
  const { caseId, slug }: { caseId: string; slug: CaseCreationStep } =
    useParams()

  const allCreditors = useSelector(selectAllCreditors)
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  const creditor = allCreditors
    ? allCreditors.find((creditor) => creditor.creditorId === selectedCreditor)
    : null

  if (creditor?.state === CreditorState.Ceased) {
    return null
  }

  if (typeof caseId !== 'string') {
    return <CaseCreator genLink={genLink} />
  }

  return <CaseCreator genLink={genLink} state={{ type: slug, caseId }} />
}

export default CreateCase
