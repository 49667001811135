import React from 'react'
import { useSelector } from 'react-redux'
import { useRouteMatch, Route } from 'react-router-dom'
import styled from 'styled-components'

import { getCollectorOnCase } from '@debbie/common/dist/cases'
import { selectCaseUpdates } from '@debbie/cortex/dist/case/selectors'
import {
  selectCaseById,
  selectCaseError,
} from '@debbie/cortex/dist/cases/selectors'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import { selectCreditorById } from '@debbie/cortex/dist/creditors/selectors'
import { selectCustomerById } from '@debbie/cortex/dist/customers/selectors'
import { useInteractionsByReference } from '@debbie/cortex/dist/interactions'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  TopNav,
  Content,
  Panel,
  ButtonGroup,
  PlaceholderLoader,
  Button,
} from '@debbie/iris/dist/components'
import CaseLoader from '@debbie/iris/dist/components/CaseLoader'
import ArrowLeft from '@debbie/iris/dist/components/Icons/ArrowLeft'
import ArrowRight from '@debbie/iris/dist/components/Icons/ArrowRight'

import { COLOR_SECONDARY, GRID_SPACING } from '../../theme'
import CaseSummary from '../../components/CaseSummary'
import { h } from '@debbie/cortex/dist'
import { Entity } from '@debbie/common/dist'

const Case = (props: { id?: string; noRedirect?: true }) => {
  const match = useRouteMatch<{ ids: string }>()
  const ids = props.id ? [props.id] : match.params.ids.split(',')
  const id = ids[0]

  const singleCase = useSelector((s: RootState) => selectCaseById(s, id))
  const customer = useSelector(
    (s: RootState) =>
      singleCase && selectCustomerById(s, singleCase.customerId),
  )
  const creditor = useSelector(
    (s: RootState) =>
      singleCase && selectCreditorById(s, singleCase.creditorId),
  )
  const updates = useSelector(
    (s: RootState) => singleCase && selectCaseUpdates(s, singleCase.caseId),
  )
  const interactionsLoadable = useInteractionsByReference(
    Entity.Case,
    singleCase?.caseId,
    true,
  )
  const interactions = h.hasData(interactionsLoadable)
    ? interactionsLoadable.data
    : null

  const caseCollectorId =
    singleCase &&
    creditor &&
    getCollectorOnCase(
      singleCase.type,
      singleCase.collectorId,
      creditor.identityCollectorId,
      creditor.defaultCollectorId,
    )
  const collector = useSelector(
    (s: RootState) =>
      caseCollectorId && selectCollectorById(s, caseCollectorId),
  )
  const caseError = useSelector((s: RootState) => selectCaseError(s, id))
  const lang = useSelector((s: RootState) => s.lang)

  const caseIsLoading = !(
    singleCase &&
    customer &&
    updates &&
    interactions &&
    collector
  )

  return (
    <div>
      <CaseLoader noRedirect={props.noRedirect} id={id} nextId={ids[1]} />
      <TopNav />
      {caseError ? (
        <Content>
          <PageBase>
            <Panel>{caseError}</Panel>
          </PageBase>
        </Content>
      ) : singleCase && singleCase.deleted ? (
        <Content>
          <PageBase>
            <Panel>{lang.cases.caseHasBeenDeleted}</Panel>
          </PageBase>
        </Content>
      ) : (
        <Content fullHeight>
          <PageBase>
            {caseIsLoading ? <PlaceholderLoader /> : <CaseSummary id={id} />}
          </PageBase>
        </Content>
      )}
      {ids.length > 1 && (
        <CaseQueue>
          Cases left: <strong>{ids.length - 1}</strong>
          <br />
          <br />
          <Route
            render={({ history }) => (
              <ButtonGroup>
                <Button
                  theme="secondary"
                  size="small"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  <ArrowLeft
                    style={{
                      verticalAlign: 'middle',
                    }}
                  />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    history.push(
                      history.location.pathname.replace(
                        ids.join(','),
                        ids.slice(1, ids.length).join(','),
                      ),
                    )
                  }}
                >
                  #{ids[1]}{' '}
                  <ArrowRight
                    style={{
                      verticalAlign: 'middle',
                    }}
                  />
                </Button>
              </ButtonGroup>
            )}
          />
        </CaseQueue>
      )}
    </div>
  )
}

export default Case

const CaseQueue = styled.div`
  position: fixed;
  border-radius: 2px;
  box-shadow: 0 20px 70px -30px rgba(0, 0, 0, 0.15);
  bottom: 120px;
  right: 0px;
  background-color: ${COLOR_SECONDARY};
  padding: ${GRID_SPACING}px;
`
