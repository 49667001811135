import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CreditorState } from '@debbie/common/dist/creditor'
import { c } from '@debbie/cortex/dist'
import {
  selectCaseListData,
  selectShouldFetchCases,
  selectIsFetchingCases,
} from '@debbie/cortex/dist/cases/selectors'
import { selectAllCreditors } from '@debbie/cortex/dist/creditors/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  TopNav,
  Content,
  Panel,
  SubMenu,
  PlaceholderLoader,
  Button,
} from '@debbie/iris/dist/components'
import CaseItem from '@debbie/iris/dist/components/CaseItem'
import { Col, Row } from '@debbie/iris/dist/components/Grid'
import THEME from '@debbie/iris/dist/theme'

const Cases = () => {
  const caseFilter = useSelector((s: RootState) => s.caseFilter)
  const lang = useSelector((s: RootState) => s.lang)
  const loading = useSelector(selectIsFetchingCases)
  const shouldFetchCases = useSelector(selectShouldFetchCases)
  const cases = useSelector(selectCaseListData)
  const allCreditors = useSelector(selectAllCreditors)

  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  const creditor = allCreditors
    ? allCreditors.find((creditor) => creditor.creditorId === selectedCreditor)
    : null

  useEffect(() => {
    loadCases()
  }, [caseFilter])

  const loadCases = () => {
    if (shouldFetchCases) {
      c.cases.getMultiple(caseFilter, 30)
    }
  }

  return (
    <div>
      <TopNav />
      <Content
        withSubMenu
        onScroll={(e) => {
          e.target.scrollHeight -
            e.target.getBoundingClientRect().height -
            e.target.scrollTop <
            2000 && loadCases()
        }}
      >
        <PageBase>
          <SubMenu
            items={[
              {
                text: lang.navigation.overview,
                link: '/',
              },
              {
                text: lang.navigation.cases,
                link: '/cases',
              },
            ]}
          >
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
            >
              {allCreditors && [0] && creditor && (
                <>
                  <div
                    data-tooltip-content={
                      creditor.state === CreditorState.Ceased
                        ? lang.cases.missingCreateAccess
                        : null
                    }
                    data-tooltip-id="tooltip-left"
                  >
                    <Button
                      size="small"
                      disabled={creditor.state === CreditorState.Ceased}
                      href="/create-case"
                    >
                      {lang.cases.createNewCase}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </SubMenu>
          <Row>
            <Col size="md" width="12">
              <Panel header={lang.cases.caseOverview}>
                <div style={{ margin: `0 -${THEME.GRID_SPACING}px` }}>
                  {cases &&
                    cases.map((singleCase) => (
                      <CaseItem key={singleCase.id} {...singleCase} />
                    ))}
                </div>
                {loading && <PlaceholderLoader />}
              </Panel>
            </Col>
          </Row>
        </PageBase>
      </Content>
    </div>
  )
}

export default Cases
