import React, { useEffect, useState } from 'react'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
} from 'recharts'

import {
  RESOLVE_DURATION_CHART_TRANSLATIONS,
  RESOLVE_DURATION_CHART_COLORS,
} from '../../theme'

import {
  chartDataChanged,
  InternalPieChartDataEntry,
  DEFAULT_OUTER_RADIUS,
} from './utils'

interface Props {
  data: InternalPieChartDataEntry[]
}

const ResolveDuration = (props: Props) => {
  const [endCasesData, setEndCasesData] = useState([])
  const [prevData, setPrevData] = useState([])

  const initChartData = () => {
    const newData = props.data
      .filter((r) => r.count > 0)
      .map((r) => ({
        name: RESOLVE_DURATION_CHART_TRANSLATIONS[
          RESOLVE_DURATION_CHART_COLORS[r.categoryName]
        ],
        label: r.categoryName,
        value: r.count,
      }))

    setEndCasesData(newData)
  }

  useEffect(() => {
    initChartData()
  }, [])

  useEffect(() => {
    const hasDataDiff = chartDataChanged(prevData, props.data)

    if (hasDataDiff) {
      setPrevData(props.data)
      initChartData()
    }
  }, [props.data])

  return (
    endCasesData && (
      <ResponsiveContainer>
        <PieChart>
          <Pie
            isAnimationActive={false}
            dataKey="value"
            data={endCasesData}
            outerRadius={DEFAULT_OUTER_RADIUS}
            label
          >
            {endCasesData.map((entry, index) => {
              return (
                <Cell
                  key={index}
                  fill={RESOLVE_DURATION_CHART_COLORS[entry.label]}
                />
              )
            })}
          </Pie>
          <Tooltip />
          <Legend
            payload={Object.values(RESOLVE_DURATION_CHART_COLORS).map(
              (color) => {
                return {
                  value: RESOLVE_DURATION_CHART_TRANSLATIONS[color],
                  color: color,
                  type: 'square',
                  id: color,
                }
              },
            )}
          />
        </PieChart>
      </ResponsiveContainer>
    )
  )
}

export default ResolveDuration
