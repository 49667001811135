import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect, Switch } from 'react-router-dom'

import { CreditorState } from '@debbie/common/dist/creditor'
import { EmbedPlacement, getEmbedAreas } from '@debbie/common/dist/embeds'
import { c } from '@debbie/cortex/dist'
import { selectCollectorById } from '@debbie/cortex/dist/collectors/selectors'
import {
  selectAllCreditors,
  selectCreditorById,
} from '@debbie/cortex/dist/creditors/selectors'
import { selectMe } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenantId } from '@debbie/cortex/dist/selectedOwners/selectors'
import { selectIsNotLoaded } from '@debbie/cortex/dist/selectors'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import { LeftNav, ContentRight, RightNav } from '@debbie/iris/dist/components'
import { Book, Bookmark } from '@debbie/iris/dist/components/Icons'
import Archive from '@debbie/iris/dist/components/Icons/Archive'
import BarChart2 from '@debbie/iris/dist/components/Icons/BarChart2'
import BatteryCharging from '@debbie/iris/dist/components/Icons/BatteryCharging'
import ExternalLink from '@debbie/iris/dist/components/Icons/ExternalLink'
import HomeIcon from '@debbie/iris/dist/components/Icons/Home'
import SettingsIcon from '@debbie/iris/dist/components/Icons/Settings'
import { BASIC_THEME, updateTheme } from '@debbie/iris/dist/theme'

import Cancel from './assets/images/cancel.svg'
import Error from './components/Error'
import { FILEBASE } from './config'
import { Home, Reports, Settings, EmbedsPage } from './pages'
import Billing from './pages/Billing'
import { Cases, Case } from './pages/cases'
import CreateCase from './pages/cases/create/CreateCase'
import CaseVouchers from './pages/CaseVouchers'
import Customer from './pages/Customer'
import CreditorIntegration from './pages/integrations/CreditorIntegration'
import CreditorIntegrations from './pages/integrations/CreditorIntegrations'
import IntegrationsPage from './pages/integrations/IntegrationsPage'
import TodoQueue from './pages/TodoQueue'

const LoggedInView = () => {
  const me = useSelector(selectMe)
  const allCreditors = useSelector(selectAllCreditors)
  const redirectTo = useSelector((s: RootState) => s.redirectTo)
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)
  const lang = useSelector((s: RootState) => s.lang)
  const tenantId = useSelector(selectTenantId)
  const tenant = useSelector(selectTenant)
  const rightNavOpen = useSelector((s: RootState) => s.rightNavOpen)
  const leftNavOpen = useSelector((s: RootState) => s.leftNavOpen)
  const creditorIds = useSelector((s: RootState) =>
    s.caseFilter ? s.caseFilter.creditorIds : [],
  )
  const creditor = useSelector((s: RootState) =>
    selectCreditorById(s, selectedCreditor),
  )
  const isCreditorNotLoaded = useSelector((s: RootState) =>
    selectIsNotLoaded(s.creditors[selectedCreditor]),
  )
  const collector = useSelector(
    (s: RootState) =>
      tenant && selectCollectorById(s, tenant.collectorIdentityId),
  )

  useEffect(() => {
    c.me.get()
  }, [])

  useEffect(() => {
    const loadData = async () => {
      await c.creditors.getAll(true)
      tenantId && (await c.tenants.get(tenantId as string))
      c.updates.types()
    }

    if (tenantId) {
      loadData()
    }
  }, [tenantId])

  useEffect(() => {
    if (tenant && !collector) {
      c.collectors.getById(tenant.collectorIdentityId)
    }
  }, [tenant])

  useEffect(() => {
    if (isCreditorNotLoaded && selectedCreditor) {
      c.creditors.get(selectedCreditor)
    }
  }, [selectedCreditor])

  useEffect(() => {
    if (me && allCreditors) {
      // Set selected creditor if none is selected or the selected is not available
      if (allCreditors[0]) {
        if (
          !selectedCreditor ||
          !allCreditors.find(
            (creditor) => creditor.creditorId === selectedCreditor,
          )
        ) {
          c.creditors.select(allCreditors[0].creditorId)
        }
      }
    }
  }, [me, allCreditors])

  updateTheme(BASIC_THEME)

  const _creditor = allCreditors
    ? allCreditors.find((creditor) => creditor.creditorId === selectedCreditor)
    : null

  const embedAreas = tenant
    ? getEmbedAreas(tenant.embeds, EmbedPlacement.CreditorWebMenuItem)
    : []

  const embedAreaLinks = embedAreas.length > 0 && {
    text: lang.embeds.creditorNavMenuItemCategory,
    exact: false,
    path: `/embeds/${embedAreas[0].embedId}`,
    links: embedAreas.map((embedArea) => ({
      text: embedArea.title,
      path: `/embeds/${embedArea.embedId}`,
      icon: <Bookmark />,
    })),
  }

  return _creditor && _creditor.state === CreditorState.Cancelled ? (
    <Error
      showIcon={true}
      image={Cancel}
      header={lang.creditors.cancelledError}
      error={lang.creditors.cancelledErrorMessage}
      collector={collector}
    ></Error>
  ) : (
    <Switch>
      {tenantId && (
        <div>
          {redirectTo.to !== '' && <RedirectTo {...redirectTo} />}
          <LeftNav
            logo={
              collector && collector.logoFileId
                ? `${FILEBASE}/${collector.logoFileId}/logo`
                : null
            }
            links={[
              {
                text: lang.navigation.home,
                path: '/',
                links: [
                  {
                    text: lang.navigation.home,
                    path: '/',
                    icon: <HomeIcon />,
                  },
                  {
                    text: lang.navigation.cases,
                    path: '/cases',
                    icon: <Archive />,
                  },
                ],
              },
              {
                text: lang.navigation.content,
                path: '/reports',
                links: [
                  {
                    text: lang.navigation.reports,
                    path: '/reports',
                    icon: <BarChart2 />,
                  },
                ],
              },
              {
                text: lang.navigation.economy,
                path: '/economy/billing',
                links: [
                  {
                    text: lang.navigation.economyBilling,
                    path: '/economy/billing',
                    icon: <BatteryCharging />,
                  },
                  {
                    text: lang.navigation.economyCaseVouchers,
                    path: '/economy/case-vouchers',
                    icon: <Book />,
                  },
                ],
              },
            ]
              .concat(embedAreaLinks)
              .concat([
                {
                  text: lang.navigation.settings,
                  path: '/settings',
                  links: (creditor && creditor.allowedIntegrations.length > 0
                    ? [
                        {
                          text: lang.navigation.integrations,
                          path: '/integrations',
                          icon: <ExternalLink />,
                        },
                      ]
                    : []
                  ).concat([
                    {
                      text: lang.navigation.myAccount,
                      path: '/settings',
                      icon: <SettingsIcon />,
                    },
                  ]),
                },
              ])}
          />
          {rightNavOpen && <RightNav />}

          <ContentRight leftNavOpen={leftNavOpen} rightNavOpen={rightNavOpen}>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/cases">
              <Cases />
            </Route>
            <Route exact path="/cases/:ids">
              <Case />
            </Route>
            <Route exact path="/create-case">
              <CreateCase />
            </Route>
            <Route exact path="/todo-queue/:updateTypeId/:id">
              <TodoQueue />
            </Route>
            <Route exact path="/todo-queue/:updateTypeId">
              <TodoQueue />
            </Route>
            <Route exact path="/create-case/:caseId/:slug">
              <CreateCase />
            </Route>
            <Route exact path="/economy/case-vouchers">
              <CaseVouchers />
            </Route>
            <Route exact path="/economy/billing">
              <Billing />
            </Route>
            <Route path="/reports">
              <Reports />
            </Route>
            <Route path="/settings">
              <Settings />
            </Route>
            <Route path="/embeds/:id">
              <EmbedsPage
                context={{
                  creditorId: creditorIds[0],
                }}
              />
            </Route>
            <Route
              path="/integrations"
              exact
              render={() => <IntegrationsPage />}
            />
            <Route
              path="/:creditorId/integrations"
              exact
              render={({ match }) => (
                <CreditorIntegrations creditorId={match.params.creditorId} />
              )}
            />
            <Route
              exact
              path={'/:creditorId/integrations/:id'}
              render={({ match, location }) => {
                const query = new URLSearchParams(location.search)
                return (
                  <CreditorIntegration
                    id={match.params.id}
                    creditorId={match.params.creditorId}
                    token={
                      query.get('token') != null
                        ? query.get('token')
                        : undefined
                    }
                    options={
                      query.get('options') != null
                        ? JSON.stringify(query.get('options'))
                        : undefined
                    }
                  />
                )
              }}
            />
            <Route exact path={'/creditors/:creditorId/customers/:customerId'}>
              {creditor && creditor.tenantId && (
                <Customer creditor={creditor} />
              )}
            </Route>
            <Route
              exact
              path={
                '/creditors/:creditorId/customers/:customerId/users/:userId'
              }
            >
              {creditor && creditor.tenantId && (
                <Customer creditor={creditor} />
              )}
            </Route>
          </ContentRight>
        </div>
      )}
    </Switch>
  )
}

export default LoggedInView

type RedirectProps = {
  to: string
  replace: boolean
}

const RedirectTo = (props: RedirectProps) => {
  const to = props.to
  const replace = props.replace

  useEffect(() => {
    c.redirect.redirect('')
  }, [])

  return to && <Redirect push={!replace} to={to} />
}
