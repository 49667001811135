import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Tooltip,
  Legend,
  Cell,
} from 'recharts'

import { CaseStatus } from '@debbie/common/dist/cases'
import { RootState } from '@debbie/cortex/dist/reducer'

import { STATUS_CHART_COLORS, STATUS_CHART_TRANSLATIONS } from '../../theme'

import {
  NOT_ENDED,
  chartDataChanged,
  getLabelWithPercentage,
  InternalPieChartDataEntry,
  DEFAULT_OUTER_RADIUS,
} from './utils'

import { useEndReasons } from '@debbie/cortex/dist/end-reasons'
import { h } from '@debbie/cortex/dist'
import { objectBy } from '@debbie/common/dist/util'

interface Props {
  statusesData: InternalPieChartDataEntry[]
  reasonsData: InternalPieChartDataEntry[]
}

const CaseStatuses = (props: Props) => {
  const endReasons = useEndReasons()

  const endReasonLookup = h.hasData(endReasons)
    ? objectBy(endReasons.data, (er) => er.id)
    : {}

  const lang = useSelector((s: RootState) => s.lang)
  const [state, setState] = useState({
    statusesData: [],
    reasonsData: [],
  })
  const [prevReasonsData, setPrevReasonsData] = useState([])
  const [prevStatusesData, setPrevStatusesData] = useState([])

  const initChartData = () => {
    const statusesData = props.statusesData
      .filter((r) => r.count > 0)
      .map((r) => ({
        name:
          lang.overview.chartStatuses[
            STATUS_CHART_TRANSLATIONS[STATUS_CHART_COLORS[r.categoryName]]
          ] || STATUS_CHART_TRANSLATIONS[STATUS_CHART_COLORS[r.categoryName]],
        label: r.categoryName,
        value: r.count,
      }))
      .sort((a, b) => {
        if (a.label === CaseStatus.ENDED) {
          return -1
        } else if (b.label === CaseStatus.ENDED) {
          return 1
        }
      })

    const reasonsData = props.reasonsData
      .filter((r) => r.count > 0)
      .map((r) => {
        const name =
          lang.endReason[endReasonLookup[r.categoryName]?.handle] ??
          endReasonLookup[r.categoryName]?.label ??
          lang.cases[r.categoryName] ??
          NOT_ENDED

        return {
          name: name,
          label: r.categoryName || NOT_ENDED,
          value: r.count,
        }
      })
      .sort((a, b) => {
        if (a.label === NOT_ENDED) {
          return 1
        } else if (b.label === NOT_ENDED) {
          return -1
        }
      })

    setState({
      statusesData,
      reasonsData,
    })
  }

  useEffect(() => {
    initChartData()
  }, [endReasons.loadingState])

  useEffect(() => {
    const hasStatusesDiff = chartDataChanged(
      prevStatusesData,
      props.statusesData,
    )
    const hasReasonsDiff = chartDataChanged(prevReasonsData, props.reasonsData)

    if (hasStatusesDiff) setPrevStatusesData(props.statusesData)
    if (hasReasonsDiff) setPrevReasonsData(props.reasonsData)

    if (hasStatusesDiff || hasReasonsDiff) {
      initChartData()
    }
  }, [props])

  return (
    <ResponsiveContainer>
      <PieChart>
        {state.statusesData.length > 0 && (
          <Pie
            isAnimationActive={false}
            data={state.statusesData}
            dataKey="value"
            outerRadius={DEFAULT_OUTER_RADIUS}
            label={getLabelWithPercentage}
          >
            {state.statusesData.map((entry, index) => {
              return (
                <Cell key={index} fill={STATUS_CHART_COLORS[entry.label]} />
              )
            })}
          </Pie>
        )}
        {state.reasonsData.length > 0 && (
          <Pie
            isAnimationActive={false}
            data={state.reasonsData}
            dataKey="value"
            innerRadius={120}
            outerRadius={DEFAULT_OUTER_RADIUS}
            fill="#ffc658"
          />
        )}
        <Tooltip />
        <Legend
          payload={Object.values(STATUS_CHART_COLORS).map((color) => {
            return {
              value:
                lang.overview.chartStatuses[STATUS_CHART_TRANSLATIONS[color]] ||
                STATUS_CHART_TRANSLATIONS[color],
              color: color,
              type: 'square',
              id: color,
            }
          })}
        />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default CaseStatuses
