import React from 'react'
import { useParams } from 'react-router-dom'

import { TopNav, EmbedsFrame } from '@debbie/iris/dist/components'

const EmbedsPage = (props: { context?: Object }) => {
  const { id } = useParams<{ id: string }>()

  return (
    <div>
      <TopNav />
      <EmbedsFrame id={id} context={props.context} />
    </div>
  )
}

export default EmbedsPage
