import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import { CaseStatus, CaseType } from '@debbie/common/dist/cases'
import { CreditorState } from '@debbie/common/dist/creditor'
import { caseFilterhash } from '@debbie/common/dist/insights'
import { h, c } from '@debbie/cortex/dist'
import { selectAllCreditors } from '@debbie/cortex/dist/creditors/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import {
  PageBase,
  TopNav,
  Content,
  SubMenu,
  Button,
} from '@debbie/iris/dist/components'
import { Row } from '@debbie/iris/dist/components/Grid'

import Dashboard from '../components/Dashboard'

const caseFilterPendingCases = {
  caseTypes: [CaseType.CLIENT],
  caseStatuses: [CaseStatus.PENDING],
}

const Home = () => {
  const allCreditors = useSelector(selectAllCreditors)
  const shouldGetCases = useSelector((s: RootState) =>
    h.isNotLoaded(s.caseList[caseFilterhash(caseFilterPendingCases)]),
  )
  const lang = useSelector((s: RootState) => s.lang)
  const selectedCreditor = useSelector((s: RootState) => s.selectedCreditor)

  const creditor = allCreditors
    ? allCreditors.find((creditor) => creditor.creditorId === selectedCreditor)
    : null

  useEffect(() => {
    shouldGetCases && c.cases.getMultiple(caseFilterPendingCases, 1000)
  }, [])

  return (
    <div>
      <TopNav />
      <Content withSubMenu>
        <PageBase>
          <SubMenu
            items={[
              {
                text: lang.navigation.overview,
                link: '/',
              },
              {
                text: lang.navigation.cases,
                link: '/cases',
              },
            ]}
          >
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'bottom',
              }}
            >
              {selectedCreditor && creditor && (
                <>
                  <div
                    data-tooltip-content={
                      creditor.state === CreditorState.Ceased
                        ? lang.cases.missingCreateAccess
                        : null
                    }
                    data-tooltip-id="tooltip-left"
                  >
                    <Button
                      size="small"
                      disabled={creditor.state === CreditorState.Ceased}
                      href="/create-case"
                    >
                      {lang.cases.createNewCase}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </SubMenu>
          <Row>
            <Dashboard />
          </Row>
        </PageBase>
      </Content>
    </div>
  )
}

export default Home
